export default defineNuxtRouteMiddleware(async (to) => {
	const { data: merchants, suspense } = useAdminMerchantsQuery();

	await suspense();

	if (!merchants.value || merchants.value.length < 1) {
		return abortNavigation(new Error('This user is not connected to any merchant'));
	}

	const merchantId = ('merchantId' in to.params) ? to.params.merchantId : null;

	if (!merchantId && merchants.value.length > 1) {
		return navigateTo({ name: 'AdminMerchantSelection' });
	}

	if (!merchantId) {
		return navigateTo({ name: 'AdminMerchantDashboard', params: { merchantId: merchants.value[0]!.id } });
	}

	if (!merchants.value.some(merchant => merchant.id === merchantId)) {
		return navigateTo({ name: 'AdminMerchantSelection' });
	}
});
